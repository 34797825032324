import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import SEO from "../components/seo"

import Radek from "../images/radek.png"
import Dorota from "../images/dorota.png"
import TitleHeader from "../components/TitleHeader"

const dorota = {
  backgroundImage: `url(${Dorota})`,
}
const radek = {
  backgroundImage: `url(${Radek})`,
}

const Zespol = () => {
  return (
    <Layout>
      <TitleHeader title="Zespół" />
      <div className="content">
        <div className="choose-service">
          <Link
            to="/zespol/radoslaw-lysek"
            className="bg-img border-img"
            style={radek}
          >
            <div className="name-person-team">dr n. med. Radosław Łysek</div>
          </Link>
          <Link
            to="/zespol/dorota-maliszewska/"
            className="bg-img border-img"
            style={dorota}
          >
            <div className="name-person-team">Dorota Maliszewska</div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export default Zespol
